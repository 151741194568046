<template>
  <div v-if="match" class="match-card" :dark="dark">
    <div :class="{hide: match.isBye}" class="bracket-match" :id="`brackt-match${this.matchIn.number}`">
      <!-- HEADER -->
      <div class="bracket-label topx text-no-wrap d-flex justify-space-between">
        <template v-if="nums || match.displayNumber">
          <template v-if="courtOrder">
            <span class="font-weight-bold">Court {{match.court}} - Match {{match.courtOrder}}</span>
            <span v-if="mapper">({{dual ? 'Dual' : 'Match'}} {{match.displayNumber}})</span>
          </template>
          <template v-else>
            <span v-if="match.isDual" class="font-weight-bold black--text">Pair {{pairN}}</span>
            <span v-else-if="match.loserMap && match.loserMap.to === 2" class="font-weight-bold black--text">Finals</span>
            <span v-else-if="match.loserMap && (match.loserMap.to === 3 || (match.loserMap.to === 4 && match.loserMap.contend))" class="font-weight-bold black--text">Semifinals</span>
            <span v-else-if="match.loserMap && match.loserMap.to === 4 && !match.loserMap.contend" class="font-weight-bold black--text">3rd Place</span>
            <span v-else class="font-weight-bold black--text">{{tournament && tournament.isLeague ? 'M' : dual ? 'Dual' : 'Match'}} {{nums ? match.number : match.displayNumber}}</span>
            <span v-if="startTime && (!(hideTimes || hideTimes2)) && !nextAvailable" class="grey--text text--darken-2">{{startTime}}</span>
            <span v-if="dual && dual.court" class="grey--text text--darken-3">Courts: {{dual.court}}</span>
            <span v-if="!dual && match.court" class="grey--text text--darken-3">Court: {{match.court}}</span>
          </template>
        </template>
      </div>
      <!-- WITH LOGO -->
      <v-card v-if="useLogos" :dark="dark">
        <div class="d-flex align-center">
          <v-img
            :src="homeLogo || 'https://vblimages.blob.core.windows.net/images/7f63b99a-b438-40ec-a202-4623e1c010c2.png'"
            max-height="22"
            width="22"
            max-width="22"
            contain
          ></v-img>
          <div class="bracket-team home pl-0 bracket-team-logo">
            <span class="d-flex">
              <span
                class="seed text-right"
                v-if="!hideSeeds"
              >{{homeSeed}}</span>
              <span
                v-if="homeDisplayName"
                class="text-truncate team"
                :class="{'font-weight-bold': match.winner === 'home', waiting: !match.homeTeam}"
              >
                <flag
                  v-if="homeCountryCode"
                  :iso="homeCountryCode"
                  :squared="false"
                />
                {{homeDisplayName}}
              </span>
              <span v-else v-html="'&nbsp;'" class="team"></span>
            </span>
            <span v-if="!showMap" class="score d-flex" :class="{'font-weight-bold': match.winner === 'home'}">
              <span class="seed" v-if="setScore">{{setScore.home}}</span>
              <span class="score-score">{{score.home}}</span>
            </span>
          </div>
        </div>
        <v-progress-linear v-if="match.status === 'Started'" height="2" stream color="success" buffer-value="0" reverse>
        </v-progress-linear>
        <div class="d-flex align-center">
          <v-img
            :src="awayLogo || 'https://vblimages.blob.core.windows.net/images/7f63b99a-b438-40ec-a202-4623e1c010c2.png'"
            max-height="22"
            width="22"
            max-width="22"
            contain
          ></v-img>
          <div class="bracket-team away pl-0 bracket-team-logo" :class="{active: match.status === 'Started'}">
            <span class="d-flex">
              <span
                class="seed text-right"
                v-if="!hideSeeds"
              >{{awaySeed}}</span>
              <span
                v-if="awayDisplayName"
                class="text-truncate team"
                :class="{'font-weight-bold': match.winner === 'away', waiting: !match.awayTeam}"
              >
                <flag
                  v-if="awayCountryCode"
                  :iso="awayCountryCode"
                  :squared="false"
                />
                {{awayDisplayName}}
              </span>
              <span v-else v-html="'&nbsp;'" class="team"></span>
            </span>
            <span v-if="!showMap" class="score d-flex" :class="{'font-weight-bold': match.winner === 'away'}">
              <span class="seed" v-if="setScore">{{setScore.away}}</span>
              <span class="score-score">{{score.away}}</span>
            </span>
          </div>
        </div>
      </v-card>
      <!-- NO LOGO -->
      <v-card v-else :dark="dark">
        <v-overlay absolute :value="lowlight"></v-overlay>
        <div class="bracket-team home">
          <span
            class="seed text-right"
            v-if="!hideSeeds"
          >{{homeSeed}}</span>
          <span
            v-if="homeDisplayName"
            class="text-truncate team"
            :class="{'font-weight-bold': match.winner === 'home', waiting: !match.homeTeam}"
          >
            <flag
              v-if="homeCountryCode"
              :iso="homeCountryCode"
              :squared="false"
            />
            {{homeDisplayName}}
          </span>
          <span v-else v-html="'&nbsp;'" class="team"></span>
          <span v-if="!showMap" class="score d-flex" :class="{'font-weight-bold': match.winner === 'home'}">
            <span class="seed" v-if="setScore">{{setScore.home}}</span>
            <span class="score-score">{{score.home}}</span>
          </span>
        </div>
        <v-progress-linear v-if="match.status === 'Started'" height="2" stream color="success" buffer-value="0" reverse></v-progress-linear>
        <div class="bracket-team away" :class="{active: match.status === 'Started'}">
          <span
            class="seed text-right"
            v-if="!hideSeeds"
          >{{awaySeed}}</span>
          <span
            v-if="awayDisplayName"
            class="text-truncate team"
            :class="{'font-weight-bold': match.winner === 'away', waiting: !match.awayTeam}"
          >
            <flag
              v-if="awayCountryCode"
              :iso="awayCountryCode"
              :squared="false"
            />
            {{awayDisplayName}}
          </span>
          <span v-else v-html="'&nbsp;'" class="team"></span>
          <span v-if="!showMap" class="score d-flex" :class="{'font-weight-bold': match.winner === 'away'}">
            <span class="seed" v-if="setScore">{{setScore.away}}</span>
            <span class="score-score">{{score.away}}</span>
          </span>
        </div>
      </v-card>
      <!-- FOOTER -->
      <div class="bracket-label bottomx text-truncate">
        <div v-if="streaming && hasStreams" class="text-center" :key="tournament.streams.length">
          <add-stream-url-dialog
            v-if="streaming"
            :bracketId="bracket.id"
            :matchNumber="matchIn.number"
            :complete="matchIn.complete"
            :forBracket="true"
            :status="matchIn.status"
          ></add-stream-url-dialog>
        </div>
        <span class="grey--text text--lighten-1" v-if="showMatch">
          {{matchIn.matchDescription}}
        </span>
        <span class="grey--text text--lighten-1" v-else-if="status === 'Complete' || hideRefs">
          {{loserText}}
        </span>
        <span class="grey--text text--lighten-1" v-else-if="refDisplay.lastNames && !division.useTeamNames && division.numOfPlayers < 3">
          Ref: {{refDisplay.lastNames | slashArray}}
        </span>
        <span class="grey--text text--lighten-1" v-else>
          {{refDisplay.name ? `Ref: ${refDisplay.name}` : loserText}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'
import RoundMixin from '@/Mixins/RoundMixin'
import { formatArray } from '@/Filters'
import flatten from '@/helpers/ArrayFlatten'
import { firstBy } from 'thenby'
import moment from 'moment'
import ArrayOfNumbersFromMap from '@/helpers/ArrayOfNumbersFromMap.js'
const AddStreamUrlDialog = () => import('@/components/LiveStream/AddStreamUrlDialog')

export default {
  mixins: [RouteMixin, RoundMixin],
  props: [
    'matchIn',
    'showMap',
    'showByes',
    'showMatch',
    'bracket',
    'pool',
    'showFooter',
    'lineText',
    'mapper',
    'map',
    'highlight',
    'lowlight',
    'nums',
    '_division',
    '_round',
    'lineupLock',
    'dark'
  ],
  data () {
    return {
      loading: false,
      winLoss: false,
      editableMatch: null,
      liveScoring: false,
      homeLine: null,
      awayLine: null,
      toLine: null,
      editing: false,
      all: false,
      swapMe: null,
      swapWith: null,
      poolRefWindow: 1,
      askComplete: false,
      settings: null,
      settingsOg: null,
      refreshKey: 1,
      timeOut: null
    }
  },
  computed: {
    ...mapGetters([
      'admin',
      'view',
      'userTeams',
      'tournament',
      'getTeam',
      'getDivision',
      'user',
      'isLiveScoring', 'matchProps'
    ]),
    lineupLock2 () {
      return this.refreshKey && this.division.lineupLockObj(this.match.startTime, this.view, this.userTeams)
    },
    lLock () {
      return this.lineupLock || this.lineupLock2
    },
    nextAvailable () {
      return this.match.court && this.match.court.toLowerCase().includes('avail')
    },
    useLogos () {
      return this.division && this.division.useLogos
    },
    streaming () {
      return this.tournament && this.tournament.props.includes('streaming')
    },
    courtOrder () {
      return (this.hideTimes || this.hideTimes2) && this.match.courtOrder
    },
    hideTimes2 () {
      const r = this.round
      return !!(r && r.hideTimes)
    },
    roundJProps () {
      return this.round && this.$store.getters.getRoundJProps(this.round.id)
    },
    hideSeeds () {
      return !!((this.roundJProps && this.roundJProps.noSeeds) || (this.division && this.$store.getters.getDivisionJProp(this.division.id, 'hideSeed')))
    },
    hideRefs () {
      return !!(this.roundJProps && this.roundJProps.noRefs)
    },
    isRef () {
      return this.user && this.tournament && this.tournament.refIds.includes(this.user.id)
    },
    matchAdmin () {
      return (this.admin || this.isRef) && !this.view.public
    },
    division () {
      return this._division || this.getDivision(this.divisionId)
    },
    round () {
      return this._round || (this.division && this.division.days.find(f => f.id === this.roundId))
    },
    matchNumberDisplay () {
      if (this.tournament && this.tournament.isLeague) return null
      if (this.match.title) return this.match.title
      const n = this.bracketCard ? this.match.displayNumber : this.match.number
      return n ? `Match ${n}` : 'Bye'
    },
    dual () {
      return (this.bracket && this.bracket.isDuals && this.bracket.allDuals.find(f => f.n === this.match.number)) || (this.pool && this.pool.isDuals && this.pool.duals.find(f => f.n === this.match.number))
    },
    dualParent () {
      return this.match.isDual && ((this.bracket && this.bracket.isDuals && this.bracket.duals.find(f => f.n === this.match.dualN)) || (this.pool && this.pool.isDuals && this.pool.duals.find(f => f.n === this.match.dualN)))
    },
    pairN () {
      if (!this.match.isDual) return 1
      return (this.match.dualMatchN || 0) + this.division.dualAdj
    },
    dualDisplay () {
      if (!this.match.isDual) return false
      return {
        home: (this.myLineups && this.myLineups.homePlayers) || `Pair ${this.pairN}`,
        away: (this.myLineups && this.myLineups.awayPlayers) || `Pair ${this.pairN}`
      }
    },
    lineups () {
      return this.match && this.match.lineups && this.match.lineups.length && {
        home: this.match.homeTeam && this.match.lineups.filter(f => f.teamId === this.match.homeTeam.teamId),
        away: this.match.awayTeam && this.match.lineups.filter(f => f.teamId === this.match.awayTeam.teamId)
      }
    },
    displayHomeLineups () {
      const ids = this.homeTeam ? [this.homeTeam.id] : []
      const t = ids.filter(f => this.lLock.userTeams.includes(f))
      return this.lLock.canDisplay || t.length > 0 || (this.lLock.view.adminAndRefs && this.lLock.userTeams.length === 0)
    },
    displayAwayLineups () {
      const ids = this.awayTeam ? [this.awayTeam.id] : []
      const t = ids.filter(f => this.lLock.userTeams.includes(f))
      return this.lLock.canDisplay || t.length > 0 || (this.lLock.view.adminAndRefs && this.lLock.userTeams.length === 0)
    },
    myLineups () {
      const getPlayers = (lineups, fullname) => {
        if (!lineups || lineups.length === 0) return null
        const l = flatten(lineups.map(m => m.players))
        const p = l && this.teamPlayers && this.teamPlayers.filter(f => l.includes(f.id))
        p && p.sort(firstBy('lastName').thenBy('firstName'))
        return p && p.map(m => `${fullname ? m.name : m.lastName}${m.jersey ? ' #' + m.jersey : ''}`).join('/')
      }
      return this.lineups && {
        home: this.lineups.home && flatten(this.lineups.home.map(m => m.positions.filter(f => f.n === this.pairN && f.type === 'dual'))),
        get homePlayers () {
          return this.home && this.home.length > 0 && getPlayers(this.home)
        },
        away: this.lineups.away && flatten(this.lineups.away.map(m => m.positions.filter(f => f.n === this.pairN && f.type === 'dual'))),
        get awayPlayers () {
          return this.away && this.away.length > 0 && getPlayers(this.away)
        }
      }
    },
    startTime () {
      const st = this.dual ? this.dual.startTime : this.match.startTime
      if (!st) return null
      const t = st.format('h:mm A')
      const d = st.format('ddd h:mm A')
      const l = st.format('ddd M/D h:mm A')
      return this.tournament.isLeague ? l : this.tournament.isOneDay ? t : d
    },
    awayTeam () {
      return this.match.awayTeam ? this.getTeam(this.match.awayTeam.teamId) : null
    },
    homeTeam () {
      return this.match.homeTeam ? this.getTeam(this.match.homeTeam.teamId) : null
    },
    homeCountryCode () {
      return this.getCountryCode(this.homeTeam)
    },
    awayCountryCode () {
      return this.getCountryCode(this.awayTeam)
    },
    refTeam () {
      return this.match.refTeam ? this.getTeam((this.match.refTeam.teamId || this.match.refTeam.id)) : null
    },
    bothTeams () {
      return !!this.homeTeam && !!this.awayTeam
    },
    displayFooter () {
      return this.showFooter && !this.match.complete && ((this.startTime && !(this.hideTimes || this.hideTimes2)) || this.match.court || this.refDisplay.name)
    },
    match () {
      return this.matchIn
    },
    games () {
      return this.matchIn.publicGames
    },
    refDisplay () {
      if (this.dual) {
        return { name: this.dual.ref || this.dual.refMap }
      }
      return this.match.manualRefTeam ? {
        name: this.match.manualRefTeam.name,
        lnames: this.match.manualRefTeam.lastNames
      } : {
        name: this.refTeam ? this.refTeam.name : this.courtOrder && this.match.courtOrder > 1 ? `Loser match ${this.match.courtOrder - 1}` : this.match.refMap,
        slot: this.refTeam ? this.refTeam.slot : null,
        lastNames: this.refTeam ? this.refTeam.lastNames : null
      }
    },
    swap () {
      if (!this.bracket) return
      const a = []
      this.match.homeTeam && a.push(this.match.homeTeam.teamId)
      this.match.awayTeam && a.push(this.match.awayTeam.teamId)
      return {
        teams: this.bracket.swappableTeams.filter(f => a.includes(f.teamId)),
        replacements: this.swapMe ? this.bracket.swappableTeams.filter(f => f.teamId !== this.swapMe.teamId) : []
      }
    },
    status () {
      return this.match.status
    },
    isBye () {
      return this.match.isBye
    },
    homeDisplayName () {
      if (this.showMap) return (this.map && this.map.home) ? this.map.home : this.homeMap
      const miht = this.matchIn && this.matchIn.homeTeam
      if (miht && miht.showName) return miht.name
      if (this.homeTeam) {
        const p = this.myLineups && this.myLineups.homePlayers
        if (p) {
          return p && this.displayHomeLineups ? this.myLineups.homePlayers : `${this.homeTeam.name} Pair ${this.pairN}${p ? ' - lineup submitted' : ''}`
        }
        if (this.homeTeam.players.length && this.homeTeam.players.length > this.division.numOfPlayers) return this.homeTeam.name
        return this.homeTeam.players.length && this.homeTeam.players.length <= 2 && this.division.numOfPlayers <= 2 && !this.division.useTeamNames ? this.homeLNames : this.homeTeam.name
      }
      if (!this.feederWasBye(this.match.homeFeeder) && !this.skinny) return this.homeMap
      if (!this.feederIsSameBracket(this.match.homeFeeder) && this.skinny) return this.homeMap
      return this.feederWasBye(this.match.homeFeeder) || !this.match.round ? this.homeMap : ''
    },
    homeLNames () {
      return this.homeTeam && this.homeTeam.players.map(m => m.lastName).join('/')
    },
    homeSeed () {
      return this.match.homeTeam ? this.match.homeTeam.seed : this.match.homeSeed || ''
    },
    homeMap () {
      return this.match.homeMap
    },
    homeLogo () {
      return this.useLogos && this.homeTeam && this.homeTeam.logo
    },
    awayDisplayName () {
      if (this.showMap) return (this.map && this.map.away) ? this.map.away : this.awayMap
      const aiht = this.matchIn && this.matchIn.awayTeam
      if (aiht && aiht.showName) return aiht.name
      if (this.awayTeam) {
        const p = this.myLineups && this.myLineups.awayPlayers
        if (p) {
          return p && this.displayAwayLineups ? this.myLineups.awayPlayers : `${this.awayTeam.name} Pair ${this.pairN}${p ? ' - Lineup Submitted' : ''}`
        }
        if (this.awayTeam.players.length && this.awayTeam.players.length > this.division.numOfPlayers) return this.awayTeam.name
        return this.awayTeam.players.length && this.awayTeam.players.length <= 2 && this.division.numOfPlayers <= 2 && !this.division.useTeamNames ? this.awayLNames : this.awayTeam.name
      }
      if (!this.feederWasBye(this.match.awayFeeder) && !this.skinny) return this.awayMap
      if (!this.feederIsSameBracket(this.match.awayFeeder) && this.skinny) return this.awayMap
      return this.feederWasBye(this.match.awayFeeder) || !this.match.round ? this.awayMap : ''
    },
    awayLNames () {
      return this.awayTeam && this.awayTeam.players.map(m => m.lastName).join('/')
    },
    awaySeed () {
      return this.match.awayTeam ? this.match.awayTeam.seed : this.match.awaySeed || ''
    },
    awayMap () {
      return this.match.awayMap
    },
    awayLogo () {
      return this.useLogos && this.awayTeam && this.awayTeam.logo
    },
    teams () {
      const t = []
      if (this.match.homeTeam) {
        t.push({
          slot: this.match.homeTeam.slot,
          team: this.homeTeam,
          winner: this.match.winner === 'home',
          map: 'home',
          seed: this.homeSeed
        })
      } else {
        t.push({
          holder: this.match.homeMap
        })
      }
      if (this.match.awayTeam) {
        t.push({
          slot: this.match.awayTeam.slot,
          team: this.awayTeam,
          winner: this.match.winner === 'away',
          map: 'away',
          seed: this.awaySeed
        })
      } else {
        t.push({
          holder: this.match.awayMap
        })
      }
      return t
    },
    toCrossover () {
      return this.bracket && this.bracket.type === 'DOUBLE_ELIM_CROSSOVER' && (this.matchIn.number === 249 || this.matchIn.number === 250)
    },
    toFinals () {
      return this.bracket && this.bracket.type === 'DOUBLE_ELIM' && this.matchIn.number === 253
    },
    break () {
      return this.$vuetify.breakpoint.name
    },
    loserTextOverride () {
      return this.roundJProps && this.roundJProps.loserText && this.roundJProps.loserText[this.match.round]
    },
    loserStr () {
      const m = this.match.isDual ? this.dualParent : this.match
      return this.loserTextOverride || this.loserStr2 || (m.loserMap ? (m.loserMap.contend) ? m.loserMap.low < 5 ? '' : `Loser to ${this.bracket.losersName} bracket - ${m.loserMap.to > 0 ? m.loserMap.to : ''}` : `Loser finishes ${this.$options.filters.ordinal(m.loserMap.to)}` : '')
    },
    loserStr2 () {
      const b = this.division && this.round && this.division.days.find(d => d.published && d.teamSelectors.find(ts => ts.fromRoundId === this.round.id && ts.type === 'MATCH_LOSERS' && ArrayOfNumbersFromMap(ts.map).includes(`${this.match.displayNumber}`)))
      return b && `Loser To ${b.name}`
    },
    loserText () {
      if (this.dual && !this.dual.winner && this.dual.courts && this.dual.courts.length) return 'Courts: ' + formatArray(this.dual.courts)
      if (this.dual && this.dual.courts && this.dual.courts.length && !this.bracket) return 'Courts: ' + formatArray(this.dual.courts)
      if (this.bracket && this.bracket.placeMatches && this.bracket.placeMatches.length > 0) return ' '
      const m = this.match.isDual ? this.dualParent : this.match
      const seed = this.bracket && this.bracket.isChallenge && m.loserMap && m.loserMap.low
      return seed ? `${this.loserTextOverride || 'Loser to'} C${seed}` : this.loserStr
    },
    score () {
      if (this.match.games.length === 1) {
        return this.match.games[0]._winner ? {
          home: this.match.games[0]._winner === 'home' ? 'W' : 'L',
          away: this.match.games[0]._winner === 'home' ? 'L' : 'W'
        } : {
          home: this.match.games[0].home,
          away: this.match.games[0].away
        }
      }
      const cg = this.match.currentGame
      if (cg) {
        return {
          home: cg.home,
          away: cg.away
        }
      }
      const show = this.match.homeSetWins || this.match.awaySetWins
      return {
        home: show ? this.match.homeSetWins : '',
        away: show ? this.match.awaySetWins : ''
      }
    },
    setScore () {
      if (!this.match.games || this.match.games.length === 1 || this.match.complete) {
        return false
      }
      const show = this.match.homeSetWins || this.match.awaySetWins
      return {
        home: show ? this.match.homeSetWins : '',
        away: show ? this.match.awaySetWins : ''
      }
    },
    advanceText () {
      if (this.toCrossover || this.toFinals || this.lineText) {
        let text = this.lineText || (this.toCrossover ? 'To Semifinals' : 'To Finals')
        if (this.bracket) {
          const pre = this.bracket.isChallenge ? 'C' : this.bracket.isQualifier ? 'Q' : false
          const seed = this.match.loserMap && this.match.loserMap.hi
          if (pre && seed) {
            text = `${text} ${pre}${seed}`
          }
        }
        return text
      }
      return false
    },
    hasStreams () {
      return this.tournament &&
        this.tournament.streams &&
        this.matchIn.status &&
        this.tournament.streams.filter(f => !f.deleted && f.matchId === this.matchIn.number && f.bracketId && f.bracketId === this.bracket.id).length
    },
    teamPlayers () {
      return this.teams && flatten(this.teams.filter(f => f.team).map(m => m.team.players))
    }
  },
  methods: {
    feederWasBye (feederN) {
      const match = this.bracket.matches.find(f => f.number === feederN)
      return match && match.isBye
    },
    feederIsSameBracket (feederN) {
      const match = this.bracket.matches.find(f => f.number === feederN)
      return match && match.isWinners === this.match.isWinners
    },
    feederExists (feederN) {
      const matchI = this.bracket.matches.findIndex(f => f.number === feederN)
      return matchI > -1
    },
    mapRefs () {
      if (this.match && !this.match.refTeam && this.match.refTeamId) {
        this.match.refTeam = this.getTeam(this.match.refTeamId)
      }
      if (this.match && !this.match.manualRefTeam && this.match.manualRefId) {
        this.match.manualRefTeam = this.getTeam(this.match.manualRefId)
      }
      this.mapDualRefs()
    },
    mapDualRefs () {
      if (this.dual) {
        this.dual.matches.forEach(match => {
          if (match && !match.refTeam && match.refTeamId) {
            match.refTeam = this.getTeam(match.refTeamId)
          }
          if (match && !match.manualRefTeam && match.manualRefId) {
            match.manualRefTeam = this.getTeam(match.manualRefId)
          }
        })
      }
    },
    checkSetRefresh () {
      if (this.lLock.hasLock && this.lLock.isLocked) return

      const d = moment(this.lLock.dtLock).diff(moment())
      this.timeOut = setTimeout(() => {
        this.refreshKey++
      }, d)
    },
    getCountryCode (team) {
      if (team) {
        return team.getCountryCode()
      }
      return ''
    }
  },
  watch: {
    'lLock.dtLock': 'checkSetRefresh',
    matchIn: function (val) {
      this.mapRefs()
      if (val && val.games) {
        this.winLoss = this.matchIn.games.filter(g => g._winner).length > 0
      }
    },
    showByes: function (n, o) {
      if (this.homeLine) {
        if (n) {
          this.homeLine.show()
        } else {
          this.feederWasBye(this.matchIn.homeFeeder) && this.homeLine.hide()
        }
      }
      if (this.awayLine) {
        if (n) {
          this.awayLine.show()
        } else {
          this.feederWasBye(this.matchIn.awayFeeder) && this.awayLine.hide()
        }
      }
    },
    displayFooter: function (n, o) {
      if (n !== o) this.$emit('size-change')
    },
    'match.refTeamId': function (n, o) {
      if (n) this.match.refTeam = this.getTeam(this.match.refTeamId)
    },
    'match.manualRefId': function (n, o) {
      if (n) this.match.manualRefTeam = this.getTeam(n)
    },
    'match.manualRefTeam': function (n, o) {
      if (n) this.match.refTeam = null
    },
    'match.refTeam': function (n, o) {
      if (n) {
        this.match.manualRefTeam = null
      } else {
        if (this.match.refTeamId) this.match.refTeam = this.getTeam(this.match.refTeamId)
      }
    }
  },
  mounted () {
    this.checkSetRefresh()
    this.mapRefs()
    if (this.match && !this.match.refTeam && this.match.refTeamId) {
      this.match.refTeam = this.getTeam(this.match.refTeamId)
    }
    if (this.match && !this.match.manualRefTeam && this.match.manualRefId) {
      this.match.manualRefTeam = this.getTeam(this.match.manualRefId)
    }
    if (this.isLiveScoring && this.matchIn.id === this.matchProps.id) {
      this.$emit('auto-click')
    }
  },
  destroyed () {
    if (this.timeOut) clearTimeout(this.timeOut)
  },
  components: {
    AddStreamUrlDialog
  }
}
</script>

<style scoped>
.no-border {
  border: none !important;
}
.match-card {
  font-size: 14px;
}
.ref,
.match-card >>> td {
  font-size: 13px;
}
.tiny {
  font-size: x-small;
}
.bordered {
  border: 1px solid rgba(0,0,0,0.12);
}
.footer td {
  height: auto;
  font-size: 11px;
  padding: 0 !important;
}
.t2 td:first-child,
.t2 >>> th:first-child {
  padding: 0 6px !important;
}
.winner {
  font-weight: 700;
}
.no-anim {
  transition: 0s;
}
@media screen and (max-width: 600px) {
  .t2 td:first-child,
  .t2 >>> th:first-child {
    padding: 0 6px !important;
  }
  .t2 >>> td>div {
    white-space: nowrap;
  }
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
}
.bracket-match {
  font-size: 0.85em;
  line-height: 1.25em;
  min-height: 75px;
}
.bracket-match div :not(.v-progress-linear) {
  min-height: 1.25em;
}
.bracket-team {
  padding: 0.4em;
  background-color: #fff;
  display: flex;
  width: 100%;
}
.bracket-team.away {
  border-top: 2px solid #eee;
}
.bracket-team.away.active {
  border-top: 0;
}
.bracket-team .seed {
  font-size: 0.85em;
  width: 1.5em;
  padding-right: 0.25em;
  color: #a3a3a3;
  max-width: 15px;
}
.bracket-team .team {
  width: 100%;
}
.bracket-team-logo {
  width: calc(100% - 22px) !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  border-radius: 5px
}
.bracket-team .score {
  width: 2em;
  text-align: right;
  display: inline-block;
}
.score-score {
  min-width: 14px;
}
.bracket-label {
  font-size: 0.9em;
  margin-left: 0.2em;
}
.bracket-label.bottom {
  margin-top: 0.2em;
}
.waiting {
  color: #BDBDBD;
  font-style: italic;
  width: 175px !important
}

.draggable--over {
  min-height: 50px !important;
}
</style>
